import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Button, ButtonGroup, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../../src/custom.css";
import Logout from "../components/Logout";
import Table from "../components/Table";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import ExcelExport from "../components/excel";
const Reports = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState({ id: '', firstName: '', reportname: '', age: '' });
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        const newFilteredRows = rows.filter(row => {
            return (
                row.id.toString().includes(filter.id) &&
                (row.firstName?.toLowerCase().includes(filter.firstName.toLowerCase()) || '') &&
                (row.reportname?.toLowerCase().includes(filter.reportname.toLowerCase()) || '') &&
                (filter.age === '' || row.age?.toString().includes(filter.age))
            );
        });
        setFilteredRows(newFilteredRows);
    }, [filter]);

    const handleOpen = () => {
        setIsopen(!isOpen);
    };

    // const handleRemove = () => {
    //     Swal.fire({
    //         text: 'Are you sure want to Delete?',
    //         showConfirmButton: true,
    //         showCancelButton: true,
    //         confirmButtonText: "OK",
    //         cancelButtonText: "Cancel",
    //         icon: 'warning'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire('', 'Removed Successfully', 'success').then((res) => {
    //                 // window.location.replace("/");
    //             });
    //         }
    //     });
    // };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setIsEditOpen(true);
    };

    const handleEditClose = () => {
        setIsEditOpen(false);
        setSelectedRow(null);
    };

    const handleEditSave = () => {
        // Logic to save the edited data
        setIsEditOpen(false);
        setSelectedRow(null);
        Swal.fire('', 'Saved Successfully', 'success');
    };

    const columns = [
        { field: 'id', headerName: 'ID', sortable: false, flex: 1, disableColumnMenu: true },
        // { field: 'firstName', headerName: 'First name', flex: 1, sortable: false, disableColumnMenu: true },
        { field: 'reportname', headerName: 'Report name', flex: 1, sortable: false, disableColumnMenu: true },
        {
            sortable: false,
            field: 'age',
            flex: 1,
            headerName: 'No.of report',
            disableColumnMenu: true
        },
        {
            field: 'fullName',
            flex: 1,
            headerName: 'Date',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: (value, row) => `${row.firstName || ''} ${row.reportname || ''}`,
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <IconButton onClick={() => handleEditOpen(params.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                        {/* <IconButton onClick={handleRemove}><DeleteIcon /></IconButton> */}
                    </ButtonGroup>
                )
            }
        },
    ];

    const rows = [
        { id: 1, reportname: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, reportname: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, reportname: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, reportname: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, reportname: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, reportname: 'Melisandre', firstName: null, age: 150 },
        { id: 7, reportname: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, reportname: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, reportname: 'Roxie', firstName: 'Harvey', age: 65 },
    ];



    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className='row'>
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown/>
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            // <BackdropLoader/>
                            <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    <Card>
                                        <CardContent>
                                            <div className="justify d-flex mb-2">
                                                <h4 className="fw-bold">Reports</h4>
                                                <ExcelExport data={filteredRows} fileName="employees" />
                                            </div>
                                            <Accordion elevation={3} >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <h5>Filter Options</h5>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by ID"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="id"
                                                                value={filter.id}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by Report Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="firstName"
                                                                value={filter.firstName}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by reports"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="reportname"
                                                                value={filter.reportname}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by list"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="age"
                                                                value={filter.age}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Table rows={filteredRows} columns={columns} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* View Modal */}
            <Dialog className="p-3" open={isViewOpen} onClose={handleViewClose} fullWidth={"sm"} maxWidth={"sm"} >
                <DialogTitle>View Reports</DialogTitle>
                <DialogContent>
                    <div>
                        <p><strong>ID:</strong> {selectedRow?.id}</p>
                        <p><strong>First Name:</strong> {selectedRow?.firstName}</p>
                        <p><strong>Last Name:</strong> {selectedRow?.reportname}</p>
                        <p><strong>Age:</strong> {selectedRow?.age}</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* Edit Modal */}
            <Dialog open={isEditOpen} onClose={handleEditClose}>
                <DialogTitle>Edit Reports</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.firstName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, firstName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.reportname || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, reportname: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Age"
                        type="number"
                        fullWidth
                        value={selectedRow?.age || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, age: e.target.value })}
                    />
                </DialogContent>
                <DialogActions className="p-3">
                    <Button variant="contained" className="grey-btn" onClick={handleEditClose}>Cancel</Button>
                    <Button className="btn" variant="contained" onClick={handleEditSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default Reports;


