import React, { useEffect, useState } from "react";
import {
    Button, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, 
    Accordion, AccordionSummary, AccordionDetails, ButtonGroup
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon, Edit as EditIcon, Visibility as VisibilityIcon, ExpandMore as ExpandMoreIcon, Add as AddIcon } from '@mui/icons-material';
import SideBar from "../components/Sidebar";
import Logout from "../components/Logout";
import Table from "../components/Table";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import "../../src/custom.css";

const Topcreators = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState({ id: '', firstName: '', lastName: '', age: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000);
    }, []);

    useEffect(() => {
        const newFilteredRows = rows.filter(row => (
            row.id.toString().includes(filter.id) &&
            (row.firstName?.toLowerCase().includes(filter.firstName.toLowerCase()) || '') &&
            (row.lastName?.toLowerCase().includes(filter.lastName.toLowerCase()) || '') &&
            (filter.age === '' || row.age?.toString().includes(filter.age))
        ));
        setFilteredRows(newFilteredRows);
    }, [filter]);

    const handleOpen = () => setIsOpen(!isOpen);

    const handleDialogOpen = (row = null) => {
        setIsEditMode(!!row);
        setSelectedRow(row);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedRow(null);
    };

    const handleDialogSave = () => {
        // Logic to save the edited or new data
        Swal.fire('', isEditMode ? 'Updated Successfully' : 'Added Successfully', 'success');
        handleDialogClose();
    };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const columns = [
        { field: 'id', headerName: 'ID', sortable: false, flex: 1, disableColumnMenu: true },
        { field: 'firstName', headerName: 'First name', flex: 1, sortable: false, disableColumnMenu: true },
        { field: 'lastName', headerName: 'Last name', flex: 1, sortable: false, disableColumnMenu: true },
        {
            field: 'age', flex: 1, headerName: 'Age', sortable: false, disableColumnMenu: true
        },
        {
            field: 'Action', flex: 1, headerName: 'Action', sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <ButtonGroup>
                    <IconButton onClick={() => handleDialogOpen(params.row)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                </ButtonGroup>
            )
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown/>
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ? <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    <Card>
                                        <CardContent>
                                            <div className="d-flex justify mb-3">
                                                <h4 className="fw-bold">Topcreators</h4>
                                                <Button className="btn" variant="contained" endIcon={<AddIcon />} onClick={() => handleDialogOpen()}>Add New</Button>
                                            </div>
                                            <Accordion elevation={3}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <h5>Filter Options</h5>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by ID"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="id"
                                                                value={filter.id}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by First Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="firstName"
                                                                value={filter.firstName}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by Last Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="lastName"
                                                                value={filter.lastName}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label="Filter by Age"
                                                                variant="outlined"
                                                                fullWidth
                                                                name="age"
                                                                value={filter.age}
                                                                onChange={handleFilterChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Table rows={filteredRows} columns={columns} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* Reusable Dialog */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth={"sm"} >
                <DialogTitle>{isEditMode ? "Edit Topcreators" : "Add Topcreators"}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.firstName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, firstName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.lastName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, lastName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Age"
                        type="number"
                        fullWidth
                        value={selectedRow?.age || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, age: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} className="grey-btn" >Cancel</Button>
                    <Button onClick={handleDialogSave} className="btn">{isEditMode ? "Update" : "Save"}</Button>
                </DialogActions>
            </Dialog>
            {/* View Dialog */}
            <Dialog open={isViewOpen} onClose={handleViewClose} fullWidth={"sm"}>
                <DialogTitle>View Topcreators</DialogTitle>
                <DialogContent>
                    <p><strong>First Name:</strong> {selectedRow?.firstName}</p>
                    <p><strong>Last Name:</strong> {selectedRow?.lastName}</p>
                    <p><strong>Age:</strong> {selectedRow?.age}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleViewClose} className="btn">Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Topcreators;
