import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../src/custom.css";
import Logout from "../../components/Logout";
import Loader from "../../components/Loader";
import Dropdown from "../../components/dropdown";
import EditIcon from '@mui/icons-material/Edit';
import { pageRoutes } from '../../config/route';
import { useSelector } from 'react-redux';
const Privacy = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { privacydata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    useEffect(() => {
        console.log(privacydata, 'privacy');

        // dispatch(fetchAPIs());
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [privacydata]);

    return (
        <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
            <div className="row">
                <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                    <SideBar />
                </div>
                <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} Privacy_card main_layout`}>
                    <div className='row'>
                        <div className="d-flex w-100 justify sticky-top">
                            <IconButton className="web-btn" onClick={handleOpen}>
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                {/* <Logout /> */}
                                <Dropdown />
                            </div>
                        </div>
                    </div>
                    {/* Page Contents */}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="row p-lg-4 p-3 mt-3 main">
                            <div className="col-12">
                                <div className="d-flex justify">
                                    <h4>Privacy policy</h4>
                                    <Button className="btn" onClick={() => window.location.replace(pageRoutes.editprivacy)} endIcon={<EditIcon />} variant="contained" >Edit</Button>
                                </div>
                                <Card elevation={6} className="mt-3" style={{ backgroundColor: "white" }}>
                                    <CardContent>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: privacydata[0].content }}
                                        />
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </div>
    );
};

export default Privacy;