import React, { useEffect, useState } from "react";
import {
    Button, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, ButtonGroup
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon, Edit as EditIcon, Visibility as VisibilityIcon, ExpandMore as ExpandMoreIcon, Add as AddIcon } from '@mui/icons-material';
import SideBar from "../../components/Sidebar";
import Logout from "../../components/Logout";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import Dropdown from "../../components/dropdown";
import "../../custom.css";
import { AgCharts } from "ag-charts-react";

const Statictics = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState({ id: '', firstName: '', lastName: '', age: '', fromDate: '', toDate: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000);
    }, []);

    const [options, setOptions] = useState({
        // Data: Data to be displayed in the chart
        data: [
            { month: "Jan", avgTemp: 2.3, iceCreamSales: 162000 },
            { month: "Mar", avgTemp: 6.3, iceCreamSales: 302000 },
            { month: "May", avgTemp: 16.2, iceCreamSales: 800000 },
            { month: "Jul", avgTemp: 22.8, iceCreamSales: 1254000 },
            { month: "Sep", avgTemp: 14.5, iceCreamSales: 950000 },
            { month: "Nov", avgTemp: 8.9, iceCreamSales: 200000 },
        ],
        // Series: Defines which chart type and data to use
        series: [{ type: "bar", xKey: "month", yKey: "iceCreamSales" }],
    });

    useEffect(() => {
        const newFilteredRows = rows.filter(row => {
            const rowDate = new Date(row.date); // Assuming the row has a date property in the correct format
            const fromDate = filter.fromDate ? new Date(filter.fromDate) : null;
            const toDate = filter.toDate ? new Date(filter.toDate) : null;

            return (
                row.id.toString().includes(filter.id) &&
                (row.firstName?.toLowerCase().includes(filter.firstName.toLowerCase()) || '') &&
                (row.lastName?.toLowerCase().includes(filter.lastName.toLowerCase()) || '') &&
                (filter.age === '' || row.age?.toString().includes(filter.age)) &&
                (!fromDate || rowDate >= fromDate) &&
                (!toDate || rowDate <= toDate)
            );
        });
        setFilteredRows(newFilteredRows);
    }, [filter]);

    const handleOpen = () => setIsOpen(!isOpen);

    const handleDialogOpen = (row = null) => {
        setIsEditMode(!!row);
        setSelectedRow(row);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedRow(null);
    };

    const handleDialogSave = () => {
        // Logic to save the edited or new data
        Swal.fire('', isEditMode ? 'Updated Successfully' : 'Added Successfully', 'success');
        handleDialogClose();
    };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const columns = [
        { field: 'id', headerName: 'ID', sortable: false, flex: 1, disableColumnMenu: true },
        { field: 'firstName', headerName: 'First name', flex: 1, sortable: false, disableColumnMenu: true },
        { field: 'lastName', headerName: 'Last name', flex: 1, sortable: false, disableColumnMenu: true },
        {
            field: 'age', flex: 1, headerName: 'Age', sortable: false, disableColumnMenu: true
        },
        {
            field: 'Action', flex: 1, headerName: 'Action', sortable: false, disableColumnMenu: true,
            renderCell: (params) => (
                <ButtonGroup>
                    <IconButton onClick={() => handleDialogOpen(params.row)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                </ButtonGroup>
            )
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35, date: '2024-08-01' },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42, date: '2024-08-10' },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45, date: '2024-08-15' },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16, date: '2024-08-20' },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, date: '2024-08-25' },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150, date: '2024-08-30' },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44, date: '2024-09-01' },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36, date: '2024-09-05' },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65, date: '2024-09-10' },
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown/>
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ? <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    <h4>Statictics</h4>
                                    <Card className="mt-3">
                                        <CardContent>
                                            <h5><b>Users</b></h5>
                                            <AgCharts options={options} />
                                        </CardContent>
                                    </Card>
                                    <Card className="mt-3">
                                        <CardContent>
                                            <h5><b>Videos</b></h5>
                                            <AgCharts options={options} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* Add/Edit Dialog */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {isEditMode ? 'Edit Payment' : 'Add New Payment'}
                    <IconButton className="btn" onClick={handleDialogClose} style={{ float: 'right' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Your form fields go here */}
                    <TextField label="First Name" variant="outlined" fullWidth className="mb-3" />
                    <TextField label="Last Name" variant="outlined" fullWidth className="mb-3" />
                    <TextField label="Age" variant="outlined" fullWidth className="mb-3" />
                    {/* Add more form fields as needed */}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleDialogSave}>
                        {isEditMode ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* View Dialog */}
            <Dialog open={isViewOpen} onClose={handleViewClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    View Payment
                    <IconButton className="btn" onClick={handleViewClose} style={{ float: 'right' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Details of the selected row */}
                    {selectedRow && (
                        <>
                            <p><strong>ID:</strong> {selectedRow.id}</p>
                            <p><strong>First Name:</strong> {selectedRow.firstName}</p>
                            <p><strong>Last Name:</strong> {selectedRow.lastName}</p>
                            <p><strong>Age:</strong> {selectedRow.age}</p>
                            {/* Add more details as needed */}
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Statictics;
