import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPIs } from "../redux/slice/slice";
import apiFunctions from "../apiKit/api";
import Swal from "sweetalert2";
const Settings = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [maxTime, setMaxtime] = useState("");
    const [edit, setEdit] = useState(false);


    const { settingdata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const dispatch = useDispatch();
    useEffect(() => {
        console.log(settingdata, 'settings');
        setUsername(settingdata[0]?.username);
        setEmail(settingdata[0]?.email);
        setMaxtime(settingdata[0]?.max_time);


        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [settingdata]);


    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "max_time": maxTime
        }
        apiFunctions.updateSettings(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    return (
        <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
            <div className="row">
                <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                    <SideBar />
                </div>
                <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`}>
                    <div className='row'>
                        <div className="d-flex w-100 justify sticky-top">
                            <IconButton className="web-btn" onClick={handleOpen}>
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                {/* <Logout /> */}
                                <Dropdown />
                            </div>
                        </div>
                    </div>
                    {/* Page Contents */}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="row p-lg-4 p-3 mt-3 main">
                            <div className="col-12">
                                <div className="d-flex justify">
                                    <h4>Settings</h4>
                                </div>
                                <Card elevation={6} className="mt-3" style={{ backgroundColor: "white" }}>
                                    <CardContent>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mt-3">
                                                <label>Name</label>
                                                <input disabled className="form-control" type="text" value={username} readOnly />
                                            </div>
                                            <div className="mt-3">
                                                <label>Email</label>
                                                <input disabled className="form-control" type="text" value={email} readOnly />
                                            </div>
                                            <div className="mt-3">
                                                <label>Max-time video upload (In seconds)</label>
                                                <input
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        const value = Math.min(1000, Number(e.target.value));
                                                        setMaxtime(value);
                                                        setEdit(true);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (["e", "E", "+", "-", "."].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    type="number"
                                                    value={maxTime}
                                                    max={1000}
                                                />
                                            </div>

                                            <button disabled={edit ? false : true} className="btn mt-3" type="submit">submit</button>
                                        </form>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </div>
    );
};

export default Settings;