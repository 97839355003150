import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import "../../../src/custom.css";
import axios from "axios";

const ShowPrivacy = () => {
    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        axios.get("https://teapoy.dci.in/api/v1/admin/privacy").then((res) => {
            if (res.status === 200) {
                setPrivacyContent(res.data?.data[0]?.content);
            } else {
                setPrivacyContent("");
            }
        })
    }
    const [privacyContent, setPrivacyContent] = useState("");
    return (
        <div>
            <Card elevation={6} className="mt-3" style={{ backgroundColor: "white" }}>
                <CardContent>
                    <div
                        dangerouslySetInnerHTML={{ __html: privacyContent }}
                    />
                </CardContent>
            </Card>
        </div>
    )
}
export default ShowPrivacy;