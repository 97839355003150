import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './components/notFound';
import Dashboard from './pages/dashboard';
import Login from './pages/Login';
import ProtectedRoute from './components/protectedRoute';
import ChangePassword from './pages/changePassword';
import { pageRoutes } from './config/route';
import Users from './pages/users';
import Reports from './pages/reports';
import Privacy from './pages/privacy/privacy';
import Terms from './pages/terms/terms';
import PrivacyEdit from './pages/privacy/editPrivacy';
import TermsEdit from './pages/terms/editTerms';
import FaqContent from './pages/faq';
import Interest from './pages/interests';
import Payments from './pages/payment';
import Topcreators from './pages/topcreators';
import Statictics from './pages/statictics/Statictics';
import ShowPrivacy from './pages/privacy/showPrivacy';
import ShowTerms from './pages/terms/showTerms';
import Settings from './pages/settings';
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={pageRoutes.login} element={<Login />} />


        {/* Protected Route Pages */}
        <Route path={pageRoutes.dashboard} element={<ProtectedRoute ><Dashboard /></ProtectedRoute>} />

        {/* users */}
        <Route path={pageRoutes.users} element={<ProtectedRoute ><Users /></ProtectedRoute>} />
        {/* statictics */}
        <Route path={pageRoutes.statictics} element={<ProtectedRoute ><Statictics /></ProtectedRoute>} />
        {/* payment */}
        <Route path={pageRoutes.payments} element={<ProtectedRoute ><Payments /></ProtectedRoute>} />
        {/* creators */}
        <Route path={pageRoutes.creators} element={<ProtectedRoute ><Topcreators /></ProtectedRoute>} />
        {/* Interest */}
        <Route path={pageRoutes.interests} element={<ProtectedRoute ><Interest /></ProtectedRoute>} />
        {/* reports */}
        <Route path={pageRoutes.reports} element={<ProtectedRoute ><Reports /></ProtectedRoute>} />
        {/* privacy-policy */}
        <Route path={pageRoutes.adminprivacy} element={<ProtectedRoute ><Privacy /></ProtectedRoute>} />
        <Route path={pageRoutes.editprivacy} element={<ProtectedRoute ><PrivacyEdit /></ProtectedRoute>} />


        {/* terms-conditions */}
        <Route path={pageRoutes.adminterms} element={<ProtectedRoute ><Terms /></ProtectedRoute>} />
        <Route path={pageRoutes.editterms} element={<ProtectedRoute ><TermsEdit /></ProtectedRoute>} />

        {/* FAQ page */}
        <Route path={pageRoutes.FAQ} element={<ProtectedRoute ><FaqContent /></ProtectedRoute>} />

         {/* Settings page */}
         <Route path={pageRoutes.settings} element={<ProtectedRoute ><Settings /></ProtectedRoute>} />

        {/* NotFound page */}
        <Route path='*' element={<ProtectedRoute ><NotFound /></ProtectedRoute>} />
        {/* changePassword */}
        <Route exact path={pageRoutes.passwordChange} element={<ProtectedRoute ><ChangePassword /></ProtectedRoute>} />

       {/* for app */}
       {/* privacy and terms conditions */}
       <Route path="/privacy-policy" element={<ShowPrivacy />} />
       <Route path="/terms-and-conditions" element={<ShowTerms />} />



      </Routes>
    </Router>
  );
}

export default App;