import { Button } from "@mui/material";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from "sweetalert2"
import { appConstants } from "../apiKit/appConstant";
import apiFunctions from "../apiKit/api";
// import Logo from "../Images/Tealogo.png"
const Logout = () => {
    const handleLogout = () => {
        Swal.fire({
            text: appConstants.logoutWarning,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            // imageUrl:Logo,            
            // icon: 'warning',
            customClass: {
                confirmButton: 'swal2-confirm',
                cancelButton: 'swal2-cancel'
            }

        }
        ).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                apiFunctions.logoutApi().then((res) => {
                    if (res.status === 200) {
                        console.log("success");
                        Swal.fire('', 'Logged out Successfully', 'success').then((res) => {
                            localStorage.clear();
                            window.location.replace("/");
                        });
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error'
                        });
                    }
                }).catch((err) => {
                    Swal.fire({
                        text: 'An error occurred during login. Please try again later.',
                        icon: 'error'
                    });
                });

            }
        }
        )
    }
    return (
        <>
            <Button onClick={handleLogout} style={{ textTransform: 'none', fontFamily: "poppin-light" }} startIcon={<LogoutIcon />} >Logout</Button>
        </>
    )
}
export default Logout;