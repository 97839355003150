export const pageRoutes = {
    login:"/",
    dashboard:"/dashboard",
    banners:"/banners",
    users:"/users",
    creators:"/creators",
    interests:"/interest",
    payments:"/payment",
    statictics:"/statictics",
    // creators:"/topcreators",
    article:"/article",
    addArticle:"/add-article",
    passwordChange:"/change-password",
    cmsHome:"/cms/home",
    FAQ:"/faq",
    reports:"/reports",
    adminprivacy:"/admin/privacy-policy",
    adminterms:"/admin/terms-and-conditions",
    editprivacy:"/edit/privacy-policy",
    editterms:"/edit/terms-and-conditions",
    settings:"/settings" 
  
  }