import method from "./method";

const apiFunctions = {

    loginApi: (payload) => method.post("login", payload),
    logoutApi:(payload)=>method.post("logout",payload),
    changepasswordApi:(payload)=>method.post("changepassword",payload),

    //dashboard
    dashboardApi:()=>method.get("dashboard"),
    //users
    getallUserApi:(payload)=>method.get("getallusers"),
    userChangestatus:(payload)=>method.post("change_status",payload),
    userApprove:(payload)=>method.post("approve",payload),



    //creators
    getcreatorsApi:(payload)=>method.get("creatorlist"),

    //faq
    getallFaq:()=>method.get("getfaq"),
    createFaq:(payload)=>method.post("createfaq",payload),
    editFaq:(payload,id)=>method.post(`editfaq/${id}`,payload),
    deleteFaq:(id)=>method.post(`deletefaq/${id}`),

    //terms and conditions
    getTermsApi:()=>method.get("getterms"),
    updatetermsApi:(payload,id)=>method.post(`update_terms/${id}`,payload),

    //privacy
    getPrivacy:()=>method.get("getprivacy"),
    updatePrivacy:(payload,id)=>method.post(`update_privacy/${id}`,payload),

    //settings
    getSettings:()=>method.get("getsetting"),
    updateSettings:(payload)=>method.post("update_settings",payload),   

}

export default apiFunctions;