import { Button, Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import React, { useState } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from "../Images/Teaboy.png"
import apiFunctions from "../apiKit/api";
import Swal from "sweetalert2";
import { pageRoutes } from "../config/route";
const Login = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };




    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "email": username,
            "password": password
        }
        apiFunctions.loginApi(json).then((res) => {
            if (res.status === 200) {
                console.log("success");
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.setItem("teapoy-token", res.data.token);
                        localStorage.setItem("mail", res.data.mailID);
                        window.location.replace(pageRoutes.dashboard)
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred during login. Please try again later.',
                icon: 'error'
            });
        });

        // localStorage.setItem("teapoy-token", "res.data.token");
        // localStorage.setItem("mail", "res.data.mailID");
        // window.location.replace(pageRoutes.dashboard)

    }
    return (
        <>
            <div className="bg" style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#ced8e1" }}>
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 col-12 align-center" style={{ height: '100vh' }}>
                        <Card elevation={5} className="p-3 w-100">
                            <CardContent >
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <div className="text-center">
                                        <img alt="" src={Logo} style={{ width: "160px", height: "45px" }} />
                                        <h5 className="fw-bold mt-3">Login to continue...</h5>
                                    </div>
                                    <div className="mt-3">
                                        <TextField className="w-100" type="text" value={username} onChange={(e) => setUsername(e.target.value)} required id="outlined-basic" label="Enter email" variant="outlined" autoComplete="new-username" />
                                    </div>
                                    <div className="mt-3">
                                        <FormControl variant="outlined" className="w-100">
                                            <InputLabel htmlFor="outlined-adornment-password"> Password*</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                label="Password*"
                                                required
                                                autoComplete="new-password"
                                            />
                                        </FormControl>
                                    </div>
                                    <input type="text" name="prevent_autofill" id="prevent_autofill" value="" style={{ display: 'none' }} autoComplete="off" />
                                    <Button type="submit" className="w-100 mt-3" variant="contained" style={{ textTransform: 'none', backgroundColor: "#32328E", fontSize: "medium" }}>Submit</Button>
                                </form>
                            </CardContent>

                        </Card>
                    </div>
                    <div className="col-lg-4"></div>
                </div>
            </div>
        </>
    )
}
export default Login;