import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFunctions from '../../apiKit/api';

// Async Thunk to call multiple APIs, including custom API functions
export const fetchAPIs = createAsyncThunk('api/fetchAPIs', async () => {
  const [userResponse] = await Promise.all([
    apiFunctions.getallUserApi(),
  ]);
  const [faqResponse] = await Promise.all([
    apiFunctions.getallFaq(),
  ]);
  const [privacyResponse] = await Promise.all([
    apiFunctions.getPrivacy(),
  ]);
  const [termsResponse] = await Promise.all([
    apiFunctions.getTermsApi(),
  ]);
  const [settingResponse] = await Promise.all([
    apiFunctions.getSettings(),
  ]);
  const [creatorResponse] = await Promise.all([
    apiFunctions.getcreatorsApi(),
  ]);

  let userdata = [];
  let creatordata = [];
  let faqdata = [];
  let privacydata = [];
  let terms = [];
  let settingdata = [];

  if (userResponse.status === 200) {
    userdata = userResponse?.data?.data;
  }
  if (faqResponse.status === 200) {
    faqdata = faqResponse?.data?.data;
  }
  if (privacyResponse.status === 200) {
    privacydata = privacyResponse?.data?.data;
  }
  if (termsResponse.status === 200) {
    terms = termsResponse?.data?.data;
  }
  if (settingResponse.status === 200) {
    settingdata = settingResponse?.data?.data;
  }
  if (creatorResponse.status === 200) {
    creatordata = creatorResponse?.data?.data;
  }

  return {
    userdata, faqdata, privacydata, terms, settingdata, creatordata
  };
});

const teapoyApiSlice = createSlice({
  name: 'teapoyapi',
  initialState: {
    userdata: [],
    creatordata: [],
    faqdata: [],
    privacydata: [],
    termsdata: [],
    settingdata: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAPIs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAPIs.fulfilled, (state, action) => {
        state.loading = false;
        state.userdata = action.payload.userdata;
        state.creatordata = action.payload.creatordata;
        state.faqdata = action.payload.faqdata;
        state.privacydata = action.payload.privacydata;
        state.termsdata = action.payload.terms;
        state.settingdata = action.payload.settingdata;
      })
      .addCase(fetchAPIs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default teapoyApiSlice.reducer;
